import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, ROLES } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HealthMemberGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  async canActivate(): Promise<boolean> {
    const idTokenResult = await this.auth.user?.getIdTokenResult(true);
    const {role}: any = idTokenResult?.claims;

    const isUserAHealthMember =
      role === ROLES.IGESTO_ADMIN ||
      role === ROLES.ADMIN ||
      role === ROLES.MEMBER;
    if (!isUserAHealthMember) {
      await this.router.navigate(['signIn']);
      return true;
    }
    return true;
  }
}
