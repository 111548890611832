import { EventEmitter, Injectable } from '@angular/core';
import { AuthService, ROLES } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userSignedInWatcher: EventEmitter<any> = new EventEmitter();
  userDataSetted: EventEmitter<boolean> = new EventEmitter();
  constructor(private authService: AuthService) {
    this.userSignedInWatcher = this.authService.userObserver;
  }

  async handleIsSignedIn(): Promise<boolean> {
    try {
      const userData = await this.authService.getUserData();

      if (!userData || !userData.role || !userData.email) {
        return false;
      }

      const {uid, role, email} = userData;
      this.authService.setUserData({uid , role, email});
      this.userDataSetted.emit(true);
      return true;
    } catch (error) {
      this.userDataSetted.emit(false);
      return false;
    }
  }

  async signIn(email: string, password: string): Promise<string> {

    try {
      const result = await this.authService.signInWithEmailAndPassword(email, password);
      const uid = result.user?.uid || '';
      const idTokenResult = await result.user?.getIdTokenResult(true);
      const {role}: any = idTokenResult?.claims; // util idTokenResult['email_verified']
      this.authService.setUserData({uid, role, email });
      return role;
    } catch (error) {
      console.log(error);
      throw Error('Login failed!');
    }
  }
}
