import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignService {
  API = 'sign/';

  constructor(private http: HttpClient, private authService: AuthService) {}

  async signIn(): Promise<void> {
    const headers = await this.authService.buildAPIAuthHeader();
    await this.http
      .post(environment.backendUrl + this.API + 'in', {}, { headers })
      .toPromise();
  }

  async signOut(): Promise<void> {
    const headers = await this.authService.buildAPIAuthHeader();
    await this.http
      .post(environment.backendUrl + this.API + 'out', {}, { headers })
      .toPromise();
  }
}
