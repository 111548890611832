import {FormlyFieldConfig} from "@ngx-formly/core";

export function minItemsValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should NOT have fewer than ${field.templateOptions.minItems} items`;
}

export function maxItemsValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should NOT have more than ${field.templateOptions.maxItems} items`;
}

export function minlengthValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should NOT be shorter than ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should NOT be longer than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be >= ${field.templateOptions.min}`;
}

export function maxValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be <= ${field.templateOptions.max}`;
}

export function multipleOfValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be multiple of ${field.templateOptions.step}`;
}

export function exclusiveMinimumValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be > ${field.templateOptions.step}`;
}

export function exclusiveMaximumValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be < ${field.templateOptions.step}`;
}

export function constValidationMessage(err : any, field: FormlyFieldConfig) {
  return !field.templateOptions ? "" : `should be equal to constant "${field.templateOptions.const}"`;
}
