<!-- Footer -->
<footer class="page-footer">

  <!-- Copyright -->
  <div class="footer-copyright">
  <p class="footer-portal-version">
      <span>Versão: {{APP_VERSION}}   </span> 
      <span>© 2023 Copyright by <a href="https://clarityhealth.com.br/">Clarity Health.</a> All rights reserved.</span>
    </p> 
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
