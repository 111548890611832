import { FormControl, ValidationErrors } from "@angular/forms";

export function MaxLengthValidator(control: FormControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    if (control.value.length > 255) {
       control.setValue(control.value.substring(0, 255));
    }

    return null;
}
